import Home from './Home/Home';
import Main from './Main/Main';
import Feedback from './Feedback/Feedback';
import React from 'react';

function HomePage() {
  return (
    <>
    <Home />
    <Main />
    <Feedback />
    </>
  );
}

export default HomePage;
