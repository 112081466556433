import React from 'react'
import Body from './Body/Body';
import Header from './Header/Header';

function AboutPage(){
    return (
        <>
            <Header/>
            <Body/>
        </>


    );
}
export default AboutPage;