import Header from './Header/Header';
import Body from './Body/Body';
import React from 'react';

function PlaceDetailPage() {
  return (
    <>
    <Header />
    <Body />
    </>
  );
}

export default PlaceDetailPage;
