import Body from './Body/Body';
import Header from './Header/Header';
import React from 'react'

function BookNowPage() {
    return (
        <>
        <Header />
        <Body />
        </>
    );
}

export default BookNowPage;