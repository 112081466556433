import Header from './Header/Header';
import Body from './Body/Body';
import React from 'react';

function HomePage() {
  return (
    <>
    <Header />
    <Body />
    </>
  );
}

export default HomePage;
