import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyDE55E_h-ApPBsssAEZfilrU5JkCjYBnYY",
    authDomain: "cooreystravel-9fdcc.firebaseapp.com",
    projectId: "cooreystravel-9fdcc",
    storageBucket: "cooreystravel-9fdcc.appspot.com",
    messagingSenderId: "286960627369",
    appId: "1:286960627369:web:3078698a551bcee95703b8",
    measurementId: "G-HL2GP28S0Z"
  };

firebase.initializeApp(firebaseConfig);

 export const firestore = firebase.firestore();
