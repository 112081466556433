import React, { useEffect, useState  } from 'react'
import './body.css'

import { useLocation } from 'react-router-dom';
import { firestore } from '../../../../firebase_config'; // Update the path if necessary
import Aos from 'aos'
import 'aos/dist/aos.css'
import Data from '../../../../place-list.json'


const Home = () => {

    const [placesData, setPlacesData] = useState([]);

    useEffect(() => {
      const fetchPlacesData = async () => {
        try {
          const collectionRef = firestore.collection('place-list');
          const snapshot = await collectionRef.get();
  
          const placesData =  snapshot.docs.map((doc) => doc.data());
          setPlacesData(placesData);
        } catch (error) {
          console.error('Error fetching places data:', error);
        }
      };
  
      fetchPlacesData();
    }, []);
  
    
    //   const Data=placesData;

//       const placeIds = placesData.map((place) => place.id);
// console.log(placeIds);
      console.log("data :")
      
      console.log(Data[2]);

      console.log(Data[2]);


    const params = new URLSearchParams(useLocation().search);
    const idParam = params.get('id');

    const selectedPlace = Data.filter((item) => {
        return item.id == parseInt(idParam)
    })
    console.log(selectedPlace);

    useEffect(() => {
        Aos.init({ duration: 1500 })
    }, [])

    return (
        <section className='placeDetailBody'>
            <div className="container">
                {selectedPlace && selectedPlace[0].placesList.map(({ id, imgSrc, location, desc }) => {
                    return (
                        <section>
                            <div className="secTitle">
                                <h3 data-aos="fade-right" className="title">
                                    {location}
                                </h3>
                            </div>
                            <div className="row" data-aos="fade-top">
                                <div className="imageContainer">
                                    <img src={imgSrc[0]} alt={location}></img>
                                </div>
                                <div className="textContainer" data-aos="fade-top">
                                    {desc}
                                </div>
                            </div>
                        </section>
                    )
                })}
            </div>
        </section>)
}

export default Home
